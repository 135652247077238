import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import JwtService from "@/core/services/jwt.service"
import store from '@/core/services/store'
import router from '@/router.js'
import { LOGOUT } from "@/core/services/store/auth.module"

/**
 * Service to call HTTP request via Axios
 */

const baseUrlApiIntegration = process.env.VUE_APP_API_SATUSEHAT_ENDPOINT
window.configuration = JSON.parse(window.localStorage.getItem('configuration'))

const configHeader = {
  headers: {
    'Authorization': `Bearer ${JwtService.getTokenBridge()}`
  },
};

const ApiBridge = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = base_url
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader(token = null) {
    // let access_token
    // if (token != null) {
    //   access_token = token
    // } else {
    //   access_token = JwtService.getTokenBridge()
    // }
    // console.log('set access token bridge', access_token)
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer ${access_token}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status == 401) {
        store.dispatch(LOGOUT)
          .then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${baseUrlApiIntegration}${resource}${slug}`).catch(error => {
      if (error.response.status == 401) {
        // store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${baseUrlApiIntegration}${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${baseUrlApiIntegration}${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  // put(resource, params) {
  //   return Vue.axios.put(`${resource}`, params)
  // },
  put(resource, params) {
    resource += "?_method=PUT"
    return Vue.axios.post(`${baseUrlApiIntegration}${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    resource += "?_method=DELETE"
    return Vue.axios.post(`${baseUrlApiIntegration}${resource}`).catch(error => {
      if (error.response.status == 401) {
        store.dispatch(LOGOUT)
          .then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  }

}

export default ApiBridge
