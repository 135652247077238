<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #7e094d"
      >
        <!-- style="background-color: #F2C98A;" -->
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-15">
          <a href="#" class="text-center mb-3">
            <!-- <img
              src="media/logos/logo-letter-1.png"
              class="max-h-70px"
              alt=""
            /> -->
            <img
              src="/images/login/Logo-Klinik-Hayandra-PutihSemua.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h5
            class="font-weight-light text-center font-size-h4 font-size-h3-lg text-white"
          >
            Dedicated to Discovery, Committed to Care
          </h5>
        </div>
        <div
          class="mt-8 aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
          }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <!-- <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Sistem Informasi
                </h3> -->
                <img
                  src="/images/login/logo-login-4.png"
                  width="250"
                  alt="Klinik Hayandra"
                />
                <!-- <span class="text-muted font-weight-bold font-size-h4"
                  >New Here?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  ></span
                > -->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Email</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <a
                    class="font-size-h6 font-weight-bolder pt-5"
                    style="color: #993366"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Lupa Password ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 text-white"
                  style="background-color: #993366"
                >
                  Masuk
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Sign Up
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your details to create your account
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Fullname"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Password"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  placeholder="Confirm password"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Lupa Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Masukan email anda untuk mereset password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4 text-white"
                  style="background-color: #993366"
                >
                  Kirim
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Batal
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!-- class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0" -->
        <div class="d-flex justify-content-center py-7 py-lg-0 text-center">
          <p class="font-size-h6">
            Copyright © 2021 Klinik Hayandra. All Rights Reserved.
            <span>Powered by Wellmagic Media Digital.</span>
          </p>
          <!-- <p>Powered by Wellmagic Media Digital.</p> -->
          <!-- <a href="#" class="text-dark font-weight-bolder font-size-h5"
            >Terms</a
          >
          <a href="#" class="text-dark ml-10 font-weight-bolder font-size-h5"
            >Plans</a
          > -->
          <!-- <a href="#" class="text-dark font-weight-bolder font-size-h5"
            >Hubungi Kami</a
          > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import ApiService from "@/core/services/api.service.js";
import ApiBridge from "@/core/services/apibridge.service.js";
import JwtService from "@/core/services/jwt.service";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import module from "@/core/modules/CrudModule.js";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";
import {
  UPDATE_SS_CLIENT_ID,
  UPDATE_SS_CLIENT_SECRET,
  UPDATE_SS_ORGANIZATION_ID,
  UPDATE_SS_ACCESS_TOKEN,
} from "@/core/services/store/tokenstore.module";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
  setSsOrganizationId,
  setSsLocationDefault,
  setConfiguration,
} from "@/core/services/jwt.service.js";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        // process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
        // process.env.BASE_URL + "images/login/cover-login-image-hms-resize.jpg"
        // process.env.BASE_URL + "images/login/cover-login-klinik.jpeg"
        // process.env.BASE_URL + "images/login/image-cover-hms-hayandra-maroon-01.jpg"
        // process.env.BASE_URL + "images/login/image-cover-hms-hayandra-maroon-03-01.jpg"
        process.env.BASE_URL +
        "images/login/image-cover-hms-hayandra-maroon-03.jpg"
      );
    },
  },
  mounted() {

    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
        agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.getSSAccessToken();
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    onSubmitLogin() {
      // this.fv.validate();
      SwalLoading.fire();

      // this.fv.on("core.form.valid", () => {
        var email = this.form.email;
        var password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        // const submitButton = this.$refs["kt_login_signin_submit"];
        // submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
          // send login request
          this.$store
            .dispatch(LOGIN, { email, password })
            // go to which page after successfully login
            .then(() => {
              let access_right, position_right;
              ApiService.get("users/me").then((data) => {
                ApiService.get("users/" + data.data.data.user_id).then(
                  (response) => {
                    console.log("user access", response);
                    if (data.data.data.position_id) {
                      ApiService.get(
                        "positions/" + data.data.data.position_id
                      ).then((result) => {
                        access_right = response.data.meta.access_right_display;
                        position_right = result.data.meta.access_right_display;

                        let merge = [...access_right, ...position_right];
                        console.log("merge", merge);

                        window.localStorage.setItem(
                          "access_right_display",
                          JSON.stringify(merge)
                        );

                        let user = data.data.data;
                        // user.access_right = access_right + ", " + position_right;
                        JwtService.setUser(JSON.stringify(user));

                        this.$router.push({ name: "dashboard" });
                        SwalLoading.close();
                      });
                    } else if (!data.data.data.position_id) {
                      window.localStorage.setItem(
                        "access_right",
                        response.data.meta.access_right
                      );

                      window.localStorage.setItem(
                        "access_right_display",
                        JSON.stringify(response.data.meta.access_right_display)
                      );

                      let user = data.data.data;
                      access_right = response.data.meta.access_right;
                      user.access_right = response.data.meta.access_right;
                      JwtService.setUser(JSON.stringify(user));

                      this.$root.$emit("setMenuActive");

                      for (let z = 0; z < 20; z++) {
                        if (z == 19) {
                          this.$router.push({ name: "dashboard" });
                          SwalLoading.close();
                        }
                      }
                    }
                  }
                );
              });

              // this.$router.push({ name: "dashboard" });
              // SwalLoading.close();
            });

          // submitButton.classList.remove(
          //   "spinner",
          //   "spinner-light",
          //   "spinner-right"
          // );
        // }, 100);
      // });

      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false,
      //   });
      // });
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        const email = this.$refs.remail.value;
        const password = this.$refs.rpassword.value;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signup_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send register request
          this.$store
            .dispatch(REGISTER, {
              email: email,
              password: password,
            })
            .then(() => this.$router.push({ name: "dashboard" }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    async getSSAccessToken() {
      // Get Token
      let login_data = {
        username: "lims-integration@wellmagic.id",
        password: "RRAMDHANI",
        grant_type: "password",
        scope: "*",
      };
      ApiService.post("oauth/token", login_data)
        .then(({ data }) => {
          // let response = await module.get("satu-sehat-settings/1");
          // if (response != null) {
          //   console.log("ssaccess token", response);
          // }
          ApiService.setHeader(data.access_token);
          ApiService.get("satu-sehat-settings/1").then((response) => {
            console.log("response", response);
            let access_token = response.data.data;
            this.$store.dispatch(UPDATE_SS_CLIENT_ID, access_token.client_id);
            this.$store.dispatch(
              UPDATE_SS_CLIENT_SECRET,
              access_token.client_secret
            );
            this.$store.dispatch(
              UPDATE_SS_ORGANIZATION_ID,
              access_token.organization_id
            );

            // Login To API Bridge & Satu Sehat
            let login_data_api_bridge = {
              username: "hayandra@wellmagic.id",
              password: "wm547u53h47",
              grant_type: "password",
              scope: "*",
              ss_client_id: access_token.client_id,
              ss_client_secret: access_token.client_secret
            };

            ApiService.bridge.post("oauth/token", login_data_api_bridge).then((response)=>{
              let data = response.data
              setTokenBridge(data.bridge.access_token)
              setRefreshTokenBridge(data.bridge.refresh_token)
              setTokenSatuSehat(data.satusehat.access_token)
              setSsOrganizationId(access_token.organization_id)
              setSsLocationDefault(access_token.location_default)

              let configuration = {
                satu_sehat_clinic_id: 2
              }
              setConfiguration(JSON.stringify(configuration))
              this.$store.dispatch(UPDATE_SS_ACCESS_TOKEN, data.satusehat.access_token);
              ApiService.setHeaderBridge()
            })
          });

          // ApiService.setHeader("unauthorized");
          console.log("store", this.$store.state.tokenstore)
        })
        .catch(({ data }) => {
          console.log("hayandra unauthorized", data);
        });
    },
  },
};
</script>

<style scoped>
.color-sample {
  color: rgb(242, 242, 243);
}

.cover-image-width {
  width: 100%;
}
</style>